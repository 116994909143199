<!--
 * @Author       : JiangChao
 * @Date         : 2024-09-10 16:00:19
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-02 15:08:29
 * @Description  : 警告设置列表
-->
<template>
  <div class="container">
    <div class="btn-layout">
      <div class="title">
        <span class="font-heavy">{{ $t("alert_setting") }}</span
        ><br /><span style="font-size: 14px">{{ $t("alert_tip_key") }}</span>
      </div>

      <el-button
        type="primary"
        @click="addAlert"
        style="width: 145px; height: 41px"
        >+ {{ $t("new_rules") }}</el-button
      >
    </div>
    <div class="table-layout">
      <div class="filter-header">
        <el-input
          v-model="search"
          disabled
          size="mini"
          :placeholder="$t('search_name')"
          class="search-style"
        >
          <template #append>
            <div class="filter-btn">
              <img src="../../assets/ic_filter.png" alt="" />
              <div @click="filterBtn" class="btn">
                {{ $t("add_filters") }}
              </div>
            </div>
          </template>
        </el-input>
      </div>

      <div class="filter-box" v-if="sceneFilterField.length > 0">
        <div
          class="filter-item"
          v-for="(item, index) in sceneFilterField"
          :key="index"
        >
          {{ item.name }} {{ item | formattedMethod }}
          {{ item | formattedValue }}
          <img
            @click="deleteFilter(index)"
            src="@/assets/ic_delete.png"
            alt=""
          />
        </div>
      </div>

      <el-row class="btn-style" v-if="sceneSelection.length > 0">
        <el-col :span="8"></el-col>
        <el-col class="tip" :span="8"
          >{{ $t("already_selected") }} {{ this.sceneSelection.length }}
          {{ $t("number_of_rules") }}</el-col
        >

        <el-col :span="3" :offset="5">
          <el-button
            @click="deleteScene()"
            style="color: #1a72f4; width: 145px"
            >{{ $t("delete") }}</el-button
          >
        </el-col>
        <el-col :span="3" :offset="1">
          <el-button @click="cancel()" style="color: #1a72f4; width: 145px">{{
            $t("cancel")
          }}</el-button>
        </el-col>
      </el-row>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        stripe
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleSceneSelectionChange"
        :empty-text="$t('no_data')"
      >
        <el-table-column
          type="selection"
          width="55"
          :selectable="rowSelectable"
        >
        </el-table-column>

        <el-table-column :label="$t('affiliated_scene')" prop="name">
          <template v-slot:default="scope">
            <!-- <span class="scene-detail" @click="goAlertDetail(scope.row)">
              {{ scope.row.name }}</span
            > -->
            <span> {{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('alert_rules')" min-width="260">
          <template v-slot:default="scope">
            <div
              v-for="(item, index) in scope.row.alarmDesc"
              :key="index"
              class="alarm-desc"
            >
              <span class="font-heavy"> {{ item.type | AlarmType }} </span>:
              {{ item.source | AlarmSensor }}
              <span style="margin-left: 5px"> is out of the range of</span>
              <!-- "1": "Temperature",
    "2": "Humidity",
    "4": "Probe temperature",
    "8": "Probe Humidity",
    "16": "Battery",
    "32": "Light",
    "64": "Shock", -->
              <span
                v-if="item.source == '2' || item.source == '8'"
                style="margin-left: 5px"
              >
                {{ item.max }}%~{{ item.min }}%
              </span>
              <div v-else style="margin-left: 5px">
                <!-- 
              大于号：> 或 &gt;
              小于号：< 或 &lt;
              大于等于：≥ 可以用 &ge; （显示为 ≥）
              小于等于：≤ 可以用 &le; （显示为 ≤） 
              -->
                <span v-if="item.source == '1' || item.source == '4'">
                  {{ item.min | convertTemperature(setSelectedTempType) }} ~
                  {{ item.max | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span>
                <span v-if="item.source == '16'"> &lt;{{ item.min }} %</span>
                <span v-if="item.source == '32'"> &gt;{{ item.max }} Lux </span>
                <span v-if="item.source == '64'"> &gt;{{ item.max }} mg </span>
                <!-- <span v-if="item.min && !item.max">
                  &gt;{{ item.min | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span>
                <span v-else-if="!item.min && item.max">
                  &lt;{{ item.min | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span>
                <span v-else>
                  {{ item.min | convertTemperature(setSelectedTempType) }} ~
                  {{ item.max | convertTemperature(setSelectedTempType) }}
                  {{ setSelectedTempType }}
                </span> -->
              </div>

              <span v-if="item.type == '2'" style="margin-left: 5px"
                >more than {{ item.duration }} minutes</span
              >
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('apply_for')">
          <template v-slot:default="scope">
            <div class="add-device-box">
              <div class="left">
                <span v-if="scope.row.deviceIds.length > 0">
                  {{ scope.row.deviceIds | deviceIdsSeparated }}
                </span>
                <span v-if="scope.row.deviceIds.length <= 0"> </span>
              </div>
              <div class="right">
                <img
                  src="../../assets/ic_plus_blue.png"
                  @click="selectDevice(scope.row)"
                  class="button-custom-height-30"
                />
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column :label="$t('note')" prop="remark">
          <template v-slot:default="scope">
            <div class="edit-box">
              <span> {{ scope.row.remark }}</span>
              <img
                :src="scope.row.ownerInd == 1 ? isEdit : isEditGray"
                @click="changeAlertNote(scope.row)"
                :style="
                  scope.row.ownerInd == 1
                    ? 'cursor: pointer'
                    : 'cursor: not-allowed'
                "
              />
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <AddAlertFilterDialog
      ref="filterRef"
      @filters="getSceneList"
    ></AddAlertFilterDialog>

    <AddDeviceToNewAlertDialog
      ref="deviceRef"
      @ok="getSceneList"
    ></AddDeviceToNewAlertDialog>

    <ChangeAlertRemarkDialog
      ref="alertRemarkRef"
      @refresh="getSceneList"
    ></ChangeAlertRemarkDialog>
  </div>
</template>

<script>
import { getSceneListApi, sceneDeleteApi } from "@/api/api.js";

import AddAlertFilterDialog from "../alert/modal/AddAlertFilterDialog.vue";
import AddDeviceToNewAlertDialog from "./modal/AddDeviceToNewAlertDialog.vue";
import ChangeAlertRemarkDialog from "../alert/modal/ChangeAlertRemarkDialog.vue";
// import config from "@/env.config.js";

export default {
  name: "Alert",
  data() {
    return {
      tableData: [],
      search: "",
      sceneSelection: [],
      sceneFilterField: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      timezone: 0,
      filters: [],
      isEdit: require("@/assets/ic_edit.png"),
      isEditGray: require("@/assets/ic_edit_gray.png"),
    };
  },

  methods: {
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },

    handleCurrentChange(val) {
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getSceneList();
    },
    addAlert() {
      this.goPages("/createAlert");
    },
    deleteFilter(index) {
      console.log("index: ", index);
      console.log("this.sceneFilterField: ", this.sceneFilterField);
      // 检查 this.sceneFilterField 是否存在且是一个数组
      if (this.sceneFilterField && Array.isArray(this.sceneFilterField)) {
        // 获取要删除的 filter 的 field
        const fieldToDelete = this.sceneFilterField[index].field;

        // 检查 this.filters 是否存在且是一个数组
        if (this.filters && Array.isArray(this.filters)) {
          // 从 filters 数组中移除具有相同 field 的 filter
          this.filters = this.filters.filter(
            (filter) => filter.field !== fieldToDelete
          );

          // 从 sceneFilterField 数组中移除当前项
          this.sceneFilterField.splice(index, 1);

          console.log("this.filters: ", this.filters);

          // 调用 getSceneList 方法更新场景列表
          this.getSceneList(this.filters);
        } else {
          console.error("filters为空");
        }
      } else {
        console.error("sceneFilterField为空");
      }
    },
    filterBtn() {
      this.$refs.filterRef.showDialog();
    },
    expandBtn() {},
    addScene() {
      this.goPages("/createScene");
    },

    getSceneList(filters) {
      this.filters = filters;
      let params = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        timezone: this.timezone,
        filters: this.filters,
        userId: "",
      };
      getSceneListApi(params).then((r) => {
        this.tableData = r.data.records || [];
        this.total = r.data.total;
        this.sceneFilterField = filters || [];
      });
    },
    pre() {
      console.log("pre: ");
    },

    next() {
      console.log("next: ");
    },

    deleteScene() {
      if (this.sceneSelection.length <= 0) {
        this.$message.error(this.$t("at_least_select_one_scene"));
        return;
      }
      let temp = [];
      this.sceneSelection.forEach((element) => {
        temp.push(element.sceneId);
      });

      // 使用 join() 方法将数组元素用逗号连接起来
      let commaSeparatedIds = temp.join(",");

      // 现在 commaSeparatedIds 包含了所有 sceneId 用逗号分隔的字符串

      this.$alert(this.$t("delete_scene_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,

        type: "warning",
      }).then(() => {
        let params = {
          sceneId: commaSeparatedIds,
        };
        sceneDeleteApi(params).then((r) => {
          console.log("=====", r);
          this.$message.success(this.$t("delete_successful"));
          this.getSceneList();
        });
      });
    },

    handleSceneSelectionChange(val) {
      console.log("val: ", val);
      this.sceneSelection = val;
    },
    // goAlertDetail(row) {
    //   console.log("row: ", row);
    //   let obj = {
    //     sceneId: row.sceneId,
    //     name: row.name,
    //     limits: row.limits,
    //     // imgUrl: config.baseURL + row.imgUrl,
    //     imgUrl: row.imgUrl,

    //     remark: row.remark,
    //     ownerName: row.ownerName,
    //   };
    //   console.log("obj: ", obj);

    //   this.goPages("/alertDetail", {
    //     data: encodeURIComponent(JSON.stringify(obj)),
    //   });
    // },
    selectDevice(row) {
      this.$refs.deviceRef.showDialog(row.sceneId,row.deviceIds);
    },
    changeAlertNote(row) {
      console.log("row: ", row);
      if (row.ownerInd == 0) {
        return;
      }
      this.$refs.alertRemarkRef.showDialog(row.sceneId);
    },
    rowSelectable(row) {
      console.log("row: ", JSON.stringify(row));
      return row.ownerInd === 1;
    },
    cancel() {
      this.sceneSelection = [];
      this.getSceneList();
    },
  },
  props: {},
  components: {
    AddAlertFilterDialog,
    ChangeAlertRemarkDialog,
    AddDeviceToNewAlertDialog,
  },
  computed: {
    selectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
  },
  watch: {
    selectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getSceneList();
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
    setSelectedTempType(newVal) {
      console.log("newVal: ", newVal);
      // this.unit = newVal == "°C" ? "C" : "F";
      this.getSceneList();
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timezone = this.selectedTimeZone;

    this.getSceneList();
    // this.getSceneFilterField();
  },
  created() {},
};
</script>

<style scoped lang="less">
.container {
  padding: 0 68px 0 38px;

  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .btn-layout {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .title {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .table-layout {
    display: flex;
    flex-direction: column;
    border: 1px solid #dddfe6;
    border-radius: 8px;

    .filter-header {
      padding: 0 20px;
      background: white;
      height: 60px;
      line-height: 60px;
      display: flex;
      flex-direction: row;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      justify-content: space-between;
      align-items: center;
      .search-style {
        width: 500px;
        .el-input .el-input--mini .el-input-group .el-input-group--append {
          height: 42px;
        }
        /deep/ .el-input__inner {
          background: white;
        }
      }
      .filter-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 20px;
        line-height: 20px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
        .btn {
          height: 20px;
          line-height: 20px;
          color: #1a72f4;
          cursor: pointer;
        }
      }
    }
    .pagination-box {
      border-top: 1px solid #dddfe6;
      border-bottom: 1px solid #dddfe6;
      padding: 0 20px;
      background: white;
      height: 60px;
      line-height: 60px;
      img {
        margin: 0 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .btn-style {
    background: #1a72f4;
    padding: 10px;

    width: 100%;
    height: 50px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    .tip {
      color: white;
    }
  }
}
.filter-box {
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  margin: 0 20px;
  .filter-item {
    height: 26px;
    margin-right: 16px;
    line-height: 26px;
    padding: 0 10px;
    display: flex;
    background: #e6e6e6;
    font-size: 12px;
    border-radius: 16px;
    flex-direction: row;
    color: #101010;
    align-items: center;
    img {
      width: 16px;
      margin-left: 5px;
      height: 16px;
    }
  }
}
.scene-detail {
  color: #1a72f4;
  text-decoration: underline;
  cursor: pointer;
}
.edit-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  img {
    width: 15px;
    height: 15px;
    margin-left: 15px;
    cursor: pointer;
  }
}
.add-device-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding: 0 40px;
  .button-custom-height-30 {
    height: 30px;
    line-height: 30px; /* 确保文本垂直居中 */
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 1px solid transparent;
    color: #1a72f4;
    text-decoration: underline;
  }
  img{
    cursor: pointer;
  }
}
.alarm-desc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
</style>
